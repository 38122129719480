<template>
    <v-dialog
        v-model="dialog.show"
        persistent
        fullscreen
        transition="dialog-bottom-transition"
    >
        <confirmation-dialog
            :actions="actions"
            :dialog="dialogs.confirmdialog"
            @confirm="accept_bundle"
        />
        <rejection-dialog
            :actions="actions"
            :dialog="dialogs.rejectdialog"
            @confirm="reject_bundle"
        />
        <v-card class=" rounded-0">
            <v-card-text
            
            v-if="!$pad"
            class="primary"
            >
                
            </v-card-text>
            <v-toolbar dark color="primary"
            :class="$ios_toolbar_class"
            :height="$ios_toolbar_height"
            >
                <v-btn icon dark @click="dialog.show = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>{{$tr("Tasks")}}</v-toolbar-title>
                <v-spacer></v-spacer>
            </v-toolbar>
            <v-container fluid class="white">
                <v-row>
                    <v-col cols="12" class="px-0">
                        <slide-buttons
                            class="px-0"
                            v-model="day"
                            :items="items"
                        />
                    </v-col>
                </v-row>
                <v-row class="">
                    <v-col cols="12">
                        <v-container>
                            <v-row
                                class="pa-4"
                                v-for="(task, index) in chosen_day_tasks"
                                :key="index"
                            >
                                <task-view
                                    :task="task"
                                    :color="
                                        task_color_map[task.status] || 'white'
                                    "
                                    :events="task_view_events(task)"
                                    :icon="get_icon(task)"
                                />
                            </v-row>
                            <v-row v-if="pending" class="pt-4 px-4">
                                <v-btn
                                    dark
                                    @click="dialogs.rejectdialog.show = true"
                                    class="red"
                                    fab
                                >
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-btn
                                    @click="dialogs.confirmdialog.show = true"
                                    dark
                                    class="success"
                                    fab
                                >
                                    <v-icon>mdi-check</v-icon>
                                </v-btn>
                            </v-row>
                        </v-container>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
    </v-dialog>
</template>

<script>
import Api from "@/api/api/index.js";
import { sync } from "vuex-pathify";
export default {
    watch: {
        days: {
            deep: true,
            handler(val) {
                this.$set(this, "day", val[0].date);
            },
        },
        async show(val) {
            if (val) {
                await this.refresh();
            }
        },
    },
    data: () => ({
        task_color_map: {
            1: "blue lighten-4",
            2: "yellow lighten-3",
            3: "light-green accent-1",
            4: "deep-orange lighten-4",
            5: "blue-grey lighten-4",
        },
        day: null,
        days: null,
        work_days: [],
        dialogs: {
            rejectdialog: {
                note: "",
                show: false,
            },
            confirmdialog: {
                text: {
                    title: "Accept this Bundle?",
                    text: "Are you sure you want to accept this bundle? on confirmation this bundle will effective emmediatly will be changed to running state.",
                    cancel: "Cancel",
                    yes: "Yes",
                },
                show: false,
            },
        },
    }),
    props: {
        actions: {
            type: Object,
            required: true,
            defualt: () => ({
                show: false,
            }),
        },
        dialog: {
            type: Object,
            default: () => {
                return {
                    show: false,
                };
            },
            required: true,
        },
    },
    methods: {
        get_icon(task) {
            return `mdi-roman-numeral-${task.type}`;
        },
        async accept_bundle() {
            this.actions.loading = true;
            try {
                const response = await Api.post(
                    "task/update/accept_or_reject_bundle",
                    {
                        bundle_id: this.bundle.bundle_id,
                        accept: true,
                    },
                    {
                        dontCache: true,
                    }
                );
                this.dialogs.confirmdialog.show = false;
                this.dialog.show = false;
                this.$emit("refresh");
            } catch (err) {
                console.log(err);
                let notification = {
                    msg:
                        err.response?.data?.error?.msg ||
                        "Error, please try again later",
                    color: "error",
                };
                this.$store.dispatch("user/notify", notification);
            }
            this.actions.loading = false;
        },
        async reject_bundle() {
            this.actions.loading = true;
            try {
                const response = await Api.post(
                    "task/update/accept_or_reject_bundle",
                    {
                        bundle_id: this.bundle.bundle_id,
                        accept: false,
                        rejection_note: this.dialogs.rejectdialog.note,
                    },
                    {
                        dontCache: true,
                    }
                );
                this.dialogs.confirmdialog.show = false;
                this.dialog.show = false;
                this.$emit("refresh");
            } catch (err) {
                console.log(err);
                let notification = {
                    msg:
                        err.response?.data?.error?.msg ||
                        "Error, please try again later",
                    color: "error",
                };
                this.$store.dispatch("user/notify", notification);
            }

            this.actions.loading = false;
        },
        async refresh() {
            try {
                const work_days = this.dialog.work_days;
                this.$set(this, "work_days", work_days);
                this.$set(this, "days", this.dialog.bundle.days);
                this.$set(this, "day", this.days[0]?.date);
                console.log(this.work_days, this.days);
            } catch (err) {
                console.log(err);
                let notification = {
                    msg:
                        err.response?.data?.error?.msg ||
                        "Error, please try again later",
                    color: "error",
                };
                this.$store.dispatch("user/notify", notification);
            }
        },
        task_text(task) {
            if (task.status == 1) {
                return `goint to -- ${task.going_to}, to do -- ${
                    task.doing_what
                }, About-- ${task.product}, to meet -- ${task.meeting_who}.
                ${task.notes ? `<br/>${task.notes}` : ""}`;
            } else if (task.status == 4) {
                const finish_details = task.finish_json;
                const finish_time = new Date(finish_details.timestamp);
                return `goint to -- ${task.going_to}, to do -- ${
                    task.doing_what
                }, About-- ${task.product}, to meet -- ${task.meeting_who}.
                ${task.notes ? `<br/>${task.notes}` : ""}
                <br/>
                <br/>
                cancelled at ${finish_time.toLocaleString()}
                cancelled because -- ${finish_details.why}
                `;
            } else if (task.status == 2) {
                const start_details = task.start_json;
                const Start_time = new Date(start_details.timestamp);
                const start_location = `https://www.google.com/maps/search/?api=1&query=${task.start_json.position.latitude},${task.start_json.position.longitude}`;
                return `goint to -- ${task.going_to}, to do -- ${
                    task.doing_what
                }, About-- ${task.product}, to meet -- ${task.meeting_who}.
                ${task.notes ? `<br/>${task.notes}` : ""}
                <br/>started at ${Start_time.toLocaleString()}
                <br/>
                <a href="${start_location}"  target="popup"  onclick="window.open('${start_location}','popup','scrollbars=yes,resizable=yes'); return false;">
                Start Position
                </a>
                `;
            } else if (task.status == 3) {
                const start_details = task.start_json;
                const Start_time = new Date(start_details.timestamp);
                const finish_details = task.finish_json;
                const finish_time = new Date(finish_details.timestamp);
                const start_location = `https://www.google.com/maps/search/?api=1&query=${task.start_json.position.latitude},${task.start_json.position.longitude}`;
                const end_location = `https://www.google.com/maps/search/?api=1&query=${task.finish_json.position.latitude},${task.finish_json.position.longitude}`;
                console.log(start_location, end_location);
                return `goint to -- ${task.going_to}, to do -- ${
                    task.doing_what
                }, About-- ${task.product}, to meet -- ${task.meeting_who}.
                ${task.notes ? `<br/>${task.notes}` : ""}
                <br/>started at ${Start_time.toLocaleString()}
                <br/>
                <a href="${start_location}"  target="popup"  onclick="window.open('${start_location}','popup','scrollbars=yes,resizable=yes'); return false;">
                Start Position
                </a>
                <br/><br/>
                went to -- ${finish_details.going_to}, did -- ${
                    finish_details.doing_what
                }, About -- ${finish_details.products}, met -- ${
                    finish_details.meeting_who
                }.
                ${finish_details.notes ? `<br/>${finish_details.notes}` : ""}
                finished at ${finish_time.toLocaleString()} 
                <br/>
                <a href="${end_location}"  target="popup"  onclick="window.open('${end_location}','popup','scrollbars=yes,resizable=yes'); return false;">
                Finish Position
                </a>
                `;
            } else {
                return `goint to -- ${task.going_to}, to do -- ${task.doing_what}, About-- ${task.product}, to meet -- ${task.meeting_who}`;
            }
        },
        task_view_events(task) {
            if (task.status == 1) {
                return [];
            } else if (task.status == 2) {
                return [];
            } else {
                return [];
            }
        },

        english_to_arabic_day_of_week_index(english_index) {
            if (english_index == 6) {
                return 0;
            } else {
                return english_index + 1;
            }
        },
        ti: (n) => {
            console.log(n);
            if (n == 6) {
                return 0;
            } else {
                return n + 1;
            }
        },
    },
    computed: {
        ...sync("user", {
            user: "user",
        }),
        loading_cover() {
            return this.$store.getters["user/loading_cover"];
        },
        chosen_day_tasks() {
            const returned =
                this.days?.filter((el) => el.date == this.day)[0]?.tasks || [];
            return returned;
        },
        items() {
            const ti = this.ti;
            const items = this.days
                ?.sort((a, b) => {
                    return ti(a.dayofweek_index) < ti(b.dayofweek_index)
                        ? -1
                        : 1;
                })
                .filter((day) => {
                    return this.work_days.includes(day.dayofweek_index);
                })
                .map((el) => ({ title: el.date, subtitle: el.dayofweek }));
            console.log("items days nigga", items, this.days);
            return items;
        },
        show() {
            return this.dialog.show;
        },
        bundle() {
            return this.dialog.bundle;
        },
        pending() {
            return this.bundle?.status == 1;
        },
    },
    components: {
        SlideButtons: () =>
            import(
                /* webpackChunkName: "workday_no_tasks" */
                "./slide_buttons.vue"
            ),
        TaskView: () =>
            import(
                /* webpackChunkName: "task" */
                "./task.vue"
            ),
        ConfirmationDialog: () =>
            import(
                /* webpackChunkName: "confirm_dialog" */
                "./confirm_dialog.vue"
            ),
        RejectionDialog: () =>
            import(
                /* webpackChunkName: "confirm_dialog" */
                "./rejection_dialog.vue"
            ),
    },
};
</script>

<style>
.emp2 {
    max-height: "" !important;
    margin-top: 50%;
    margin-bottom: 50%;
    overflow-y: auto;
}
</style>
